import React, { Component } from 'react';
import SideMenu from '../admin/SideMenu';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import TopMenu from '../admin/TopBar';
import UploadImage from '../utils/UploadImage';
import { appDatabasePrimaryFunctions, base } from '../../base';
import '../../styles/css/AdminMain.css';
import { Toast, ErrorMessage, WarningMessage } from '../utils/HelpfulFunction';
import BasicDropzone from '../utils/Dropzone';
import Preview from "../utils/PreviewHomeScreen";

class SetUpHomeScreen extends Component {
    constructor(props) {
        super(props);
        this.UploadImage = new UploadImage();
        this.state = {
            itemsList: [],
            loading: true,
            modal: false,
            itemName: "",
            itemLink: "",
            imageUrl: "",
            itemToEdit: null,
            editingItem: false,
            buttonType: 'isWebsiteLink',
            backgroundImage: null,
            hasUnsavedChanges: false
        };
        this.handleSubmit = this.handleSubmit.bind(this);
        this.toggle = this.toggle.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.saveChanges = this.saveChanges.bind(this);
        this.handleBeforeUnload = this.handleBeforeUnload.bind(this);
    }

    async componentDidMount() {
        this.homeButtonsBuiltRef = base.bindToState(`homeButtonsBuilt`, {
            context: this,
            state: 'itemsList',
            query: {
                orderByChild: 'order'
            },
            asArray: true,
            then() {
                this.setState({ loading: false });
            },
            catch(e) {
                this.setState({ loading: false });
            }
        });

        const backgroundImage = await appDatabasePrimaryFunctions.ref('settings/backgroundImage').once('value');
        if (backgroundImage.exists()) {
            this.setState({ backgroundImage: backgroundImage.val() });
        }

        window.addEventListener('beforeunload', this.handleBeforeUnload);
    }

    componentWillUnmount() {
        if (this.state.imageUrlPreview) {
            URL.revokeObjectURL(this.state.imageUrlPreview.preview);
        }
        base.removeBinding(this.homeButtonsBuiltRef);
        window.removeEventListener('beforeunload', this.handleBeforeUnload);
    }

    handleBeforeUnload(event) {
        if (this.state.hasUnsavedChanges) {
            const message = 'You have unsaved changes. Are you sure you want to leave?';
            event.returnValue = message;
            return message;
        }
    }

    onDrop(files, rejected, myArgument) {
        if (rejected.length > 0) {
            ErrorMessage.fire({
                title: 'Image cannot be uploaded',
                text: 'Make sure the image is less than 2mbs and it is an accepted file type'
            })
            return;
        }
        this.setState({ loading: true, hasUnsavedChanges: true })

        this.UploadImage.upload_file(files[0]).then(res => {
            this.setState({ loading: false })
            if (res.error) {
                ErrorMessage.fire({
                    title: 'Image cannot be uploaded',
                    text: res.error,
                })
            } else if (res.imageUrl) {
                const nameToUpdate = myArgument + "Preview"
                const fileToUpdate = files[0]
                this.setState({
                    [myArgument]: res.imageUrl,
                    [nameToUpdate]: ({
                        fileToUpdate,
                        preview: URL.createObjectURL(fileToUpdate)
                    })
                });
            } else {
                ErrorMessage.fire({
                    title: 'Image cannot be uploaded',
                    text: 'Something went wrong, please re-upload your image and try again!'
                })
            }
        })
    }

    handleChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        this.setState({ [target.name]: value, hasUnsavedChanges: true });
    }

    handleSubmit(event) {
        event.preventDefault();
        if (this.state.loading) {
            return;
        }

        const { itemName, itemLink, itemsList, buttonType, imageUrl, itemToEdit } = this.state;

        if (!itemName || itemName.length > 50) {
            ErrorMessage.fire({
                title: 'Oh uh!',
                text: 'Name cannot be longer than 50 characters or be left blank!',
            });
            return;
        }

        const image = imageUrl || this.state.editImage || "";

        if (!image) {
            ErrorMessage.fire({
                title: 'Not all fields filled out!',
                text: 'Please enter them and try again'
            });
            return;
        }

        const itemToSave = {}
        itemToSave['header'] = itemName;
        itemToSave['link'] = itemLink;
        itemToSave['image'] = image;
        itemToSave['buttonType'] = buttonType;
        itemToSave['order'] = (this.state.itemToEdit && this.state.itemToEdit.order) || itemsList.length + 1;
        this.setState({ loading: true })
        itemToSave['id'] = (this.state.itemToEdit && this.state.itemToEdit.key) || appDatabasePrimaryFunctions.ref().push().key;

        let updatedItemsList = [...itemsList];
        if (itemToEdit) {
            updatedItemsList = updatedItemsList.map(item => item.key === itemToSave.id ? itemToSave : item);
        } else {
            updatedItemsList.push(itemToSave);
        }

        this.setState({
            itemsList: updatedItemsList,
            itemName: "",
            itemLink: "",
            imageUrl: "",
            itemToEdit: null,
            editingItem: false,
            modal: false,
            buttonType: 'isWebsiteLink',
            loading: false,
            hasUnsavedChanges: true
        });
    }

    async saveChanges() {
        this.setState({ loading: true });
        const itemListCopyObject = {};
        this.state.itemsList.forEach(item => {
            itemListCopyObject[item.id || item.key] = { ...item, key: null, id: item.id || item.key };
        });

        await appDatabasePrimaryFunctions.ref('homeButtonsBuilt').set(itemListCopyObject);
        this.setState({ loading: false, hasUnsavedChanges: false });
        Toast.fire({
            title: 'Changes saved successfully!'
        });
    }

    editItem(event) {
        event.preventDefault();
        const item = this.state.itemsList[event.target.value];
        this.setState({
            modal: true,
            itemName: item.header,
            itemLink: item.link,
            imageUrl: item.image,
            itemToEdit: item,
            editingItem: true,
            buttonType: item.buttonType,
            hasUnsavedChanges: true
        });
    }

    async deleteItem(e) {
        e.preventDefault();
        const index = e.target.value;
        const response = await WarningMessage.fire({
            title: 'Delete?',
            text: 'Are you sure you want to do this?  You will no longer be able to use this anymore',
            confirmButtonText: 'Delete'
        });

        if (response.value) {
            let updatedItemsList = this.state.itemsList.filter((_, idx) => idx !== parseInt(index));
            updatedItemsList = updatedItemsList.map((item, idx) => {
                item.order = idx + 1;
                return item;
            });

            this.setState({ itemsList: updatedItemsList, hasUnsavedChanges: true });
        }
    }

    toggle() {
        this.setState({
            modal: !this.state.modal,
            itemName: "",
            itemLink: "",
            imageUrl: "",
            itemToEdit: null,
            editingItem: false,
            buttonType: 'isWebsiteLink',
            hasUnsavedChanges: true
        });
    }

    async changeOrder(item, direction, index) {
        let itemsListCopyArray = this.state.itemsList || [];
        let originalOrder = item.order || index;
        const itemId = item.id || item.key;

        if ((originalOrder === 1 && direction === 1) || (originalOrder === itemsListCopyArray.length && direction === -1)) {
            return;
        }

        let newOrder = originalOrder - direction;
        itemsListCopyArray = itemsListCopyArray.map((item, idx) => {
            if (item.order === newOrder) {
                item.order = originalOrder;
            } else if (item.id === itemId) {
                item.order = newOrder;
            }
            return item;
        });

        this.setState({ itemsList: itemsListCopyArray, hasUnsavedChanges: true });
    }

    render() {
        const isDev = process.env.REACT_APP_FIREBASE_PROJECT_ID === "anothertestdb-be6c5";
        const isCheckers = process.env.REACT_APP_FIREBASE_PROJECT_ID === "checkersdbtest";
        const { loading, itemsList = [], imageUrlPreview = {}, backgroundImage, hasUnsavedChanges } = this.state;
        return (
            <div className="admin-wrapper">
                <div className="loading-screen" style={{ display: loading ? 'block' : 'none' }} />
                <SideMenu />
                <TopMenu />
                <div className="admin-main-panel">
                    <div className="row">
                        <div className="col-md-8">
                            <div className="card">
                                <div className="card-body">
                                    <p className="admin-header-text" style={{ marginBottom: 0 }}>Home Buttons</p>
                                    <p className="admin-subheader-text">These items will make up the main buttons of your app (1400x450)</p>
                                    <button className="btn btn-primary btn-lg create-prize-button" style={{ fontSize: 20, marginLeft: 20 }} onClick={this.toggle}>Add Item</button>
                                    <button className="btn btn-primary btn-lg save-changes-button" style={{ fontSize: 20, marginLeft: 20 }} onClick={this.saveChanges}>Save Changes</button>
                                    {hasUnsavedChanges && <p style={{ color: 'red', marginLeft: 20 }}>You have unsaved changes</p>}
                                    <div className="admin-grid-container four-columns" style={{ marginTop: 20 }}>
                                        {
                                            itemsList.sort((a, b) => a.order > b.order ? 1 : -1).map((item, i) => {
                                                let orderNumber = item.order || i + 1;
                                                return (
                                                    <div key={i} className="card">
                                                        <div className="card-body" align="center">
                                                            <p style={{ marginTop: 5 }}>{item.header}</p>
                                                            {item.link &&
                                                                <p style={{ marginTop: 5 }}>{item.link}</p>
                                                            }
                                                            {itemsList.length > 1 &&
                                                                <>
                                                                    <p style={{ marginTop: 5 }}>Order: {orderNumber}</p>
                                                                    <p>
                                                                        <span style={{ display: orderNumber <= 1 && "none", cursor: "pointer" }} onClick={() => this.changeOrder(item, 1, i + 1)}>
                                                                            ⬅️
                                                                        ️</span>
                                                                        <span style={{ display: itemsList.length <= i + 1 && "none", cursor: "pointer" }} onClick={() => this.changeOrder(item, -1, i + 1)}>
                                                                            ➡️
                                                                        </span></p>
                                                                </>
                                                            }
                                                            <p>
                                                                <img width="80%" height="auto" src={item.image} alt="" />
                                                            </p>
                                                            <button className="btn btn-primary btn-lg edit-button" style={{ marginRight: 5, marginBottom: 10 }} onClick={this.editItem.bind(this)} value={i}><span className="fa fa-ellipsis-v" /> Edit</button>
                                                            <button className="btn btn-primary btn-lg delete-button" style={{ marginBottom: 10 }} onClick={this.deleteItem.bind(this)} value={i}><span className="fa fa-trash-o" /> Delete</button>
                                                        </div>
                                                    </div>
                                                );
                                            })
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <Preview imagePreviews={itemsList} backgroundImage={backgroundImage} isCheckers={isCheckers || isDev} />
                        </div>
                    </div>
                </div>
                <Modal isOpen={this.state.modal} toggle={this.toggle} style={{ width: '90%' }} id="myModal">
                    <div className="loading-screen" style={{ display: this.state.loading ? 'block' : 'none' }} />
                    <ModalHeader toggle={this.toggle}>Add Item</ModalHeader>
                    <ModalBody>
                        <div className="container-out">
                            <div className="question-box question-form">
                                <form className="pl-3 pr-3" onSubmit={this.handleSubmit} id="create-email-form">
                                    <div className="form-group">
                                        <label htmlFor="itemName">Name: </label>
                                        <input id="itemName" name="itemName" type="text" className="form-control" value={this.state.itemName} onChange={this.handleChange} placeholder="Name" />
                                    </div>
                                    <div className="row">
                                        {process.env.REACT_APP_HAS_PRIZES === "true" && (
                                            <>
                                                <div className="col-12 mb-3">
                                                    <div className="row">
                                                        <div className="col-md-4">
                                                            <div className="form-check">
                                                                <input
                                                                    id="isWebsiteLink"
                                                                    name="buttonType"
                                                                    type="radio"
                                                                    className="form-check-input"
                                                                    value="isWebsiteLink"
                                                                    checked={this.state.buttonType === "isWebsiteLink"}
                                                                    onChange={this.handleChange}
                                                                />
                                                                <label className="form-check-label" htmlFor="isWebsiteLink">
                                                                    Website Link
                                                                </label>
                                                            </div>
                                                        </div>
                                                        {process.env.REACT_APP_HAS_TICKETMASTER === "true" && (
                                                            <div className="col-md-4">
                                                                <div className="form-check">
                                                                    <input
                                                                        id="isTicketMaster"
                                                                        name="buttonType"
                                                                        type="radio"
                                                                        className="form-check-input"
                                                                        value="isTicketMaster"
                                                                        checked={this.state.buttonType === "isTicketMaster"}
                                                                        onChange={this.handleChange}
                                                                    />
                                                                    <label className="form-check-label" htmlFor="isTicketMaster">
                                                                        Ticketmaster SDK Button
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        )}
                                                        <div className="col-md-4">
                                                            <div className="form-check">
                                                                <input
                                                                    id="isAnotherSliderHolder"
                                                                    name="buttonType"
                                                                    type="radio"
                                                                    className="form-check-input"
                                                                    value="isAnotherSliderHolder"
                                                                    checked={this.state.buttonType === "isAnotherSliderHolder"}
                                                                    onChange={this.handleChange}
                                                                />
                                                                <label className="form-check-label" htmlFor="isAnotherSliderHolder">
                                                                    Last Slider Item Link
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        {process.env.REACT_APP_HAS_PLAYER_PICK === "true" && (
                                                            <div className="col-md-4">
                                                                <div className="form-check">
                                                                    <input
                                                                        id="isPlayerPick"
                                                                        name="buttonType"
                                                                        type="radio"
                                                                        className="form-check-input"
                                                                        value="isPlayerPick"
                                                                        checked={this.state.buttonType === "isPlayerPick"}
                                                                        onChange={this.handleChange}
                                                                    />
                                                                    <label className="form-check-label" htmlFor="isPlayerPick">
                                                                        Player Pick Button
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                                <div className="col-12 mb-3">
                                                    <div className="row">
                                                        {process.env.REACT_APP_HAS_BADGES === "true" && (
                                                            <div className="col-md-4">
                                                                <div className="form-check">
                                                                    <input
                                                                        id="isBadges"
                                                                        name="buttonType"
                                                                        type="radio"
                                                                        className="form-check-input"
                                                                        value="isBadges"
                                                                        checked={this.state.buttonType === "isBadges"}
                                                                        onChange={this.handleChange}
                                                                    />
                                                                    <label className="form-check-label" htmlFor="isBadges">
                                                                        Badges Button
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        )}
                                                        {process.env.REACT_APP_HAS_CHECK_IN === "true" && (
                                                            <div className="col-md-4">
                                                                <div className="form-check">
                                                                    <input
                                                                        id="isCheckIn"
                                                                        name="buttonType"
                                                                        type="radio"
                                                                        className="form-check-input"
                                                                        value="isCheckIn"
                                                                        checked={this.state.buttonType === "isCheckIn"}
                                                                        onChange={this.handleChange}
                                                                    />
                                                                    <label className="form-check-label" htmlFor="isCheckIn">
                                                                        Check In Button
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        )}
                                                        {process.env.REACT_APP_HAS_PRIZES === "true" && (
                                                            <div className="col-md-4">
                                                                <div className="form-check">
                                                                    <input
                                                                        id="isPrizes"
                                                                        name="buttonType"
                                                                        type="radio"
                                                                        className="form-check-input"
                                                                        value="isPrizes"
                                                                        checked={this.state.buttonType === "isPrizes"}
                                                                        onChange={this.handleChange}
                                                                    />
                                                                    <label className="form-check-label" htmlFor="isPrizes">
                                                                        Reward Store Button
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                                <div className="col-12 mb-3">
                                                    <div className="row">
                                                        {process.env.REACT_APP_HAS_RADIO === "true" && (
                                                            <div className="col-md-4">
                                                                <div className="form-check">
                                                                    <input
                                                                        id="isRadio"
                                                                        name="buttonType"
                                                                        type="radio"
                                                                        className="form-check-input"
                                                                        value="isRadio"
                                                                        checked={this.state.buttonType === "isRadio"}
                                                                        onChange={this.handleChange}
                                                                    />
                                                                    <label className="form-check-label" htmlFor="isRadio">
                                                                        Radio Button
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            </>
                                        )}
                                        {this.state.buttonType === "isWebsiteLink" && (
                                            <div className="col-12 mb-3">
                                                <div className="form-group">
                                                    <label htmlFor="itemLink">Link:</label>
                                                    <input
                                                        id="itemLink"
                                                        name="itemLink"
                                                        type="url"
                                                        className="form-control"
                                                        value={this.state.itemLink}
                                                        onChange={this.handleChange}
                                                        placeholder="https://linktogoto.com"
                                                    />
                                                </div>
                                            </div>
                                        )}
                                    </div>

                                    <div className="form-group" align="center">
                                        <label htmlFor="imageUrl" style={{ width: '100%' }}>
                                            Image
                                            <span style={{ cursor: "pointer", display: imageUrlPreview.preview || this.state.imageUrl ? "" : "none" }} onClick={() => this.setState({ imageUrl: null, imageUrlPreview: {} })}>
                                                ❌
                                            </span>
                                        </label>
                                        <img src={this.state.imageUrl} width="auto" height="100px" style={{ display: this.state.editingItem ? '' : 'none' }} alt="" />
                                        <BasicDropzone
                                            onDrop={(acceptedFiles, fileRejections) => this.onDrop(acceptedFiles, fileRejections, "imageUrl")}
                                            preview={imageUrlPreview.preview}
                                        />
                                    </div>
                                    <div className="form-group text-center">
                                        <button className="btn btn-primary btn-lg submit-button" id="submitButton">Submit</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </ModalBody>
                </Modal>
            </div>
        );
    }
}

export default SetUpHomeScreen;
