import React, { Component } from 'react'
import '../../styles/css/SideMenu.css'
import '../../styles/css/AdminMain.css'
import {app, appDatabasePrimaryFunctions} from '../../base';
import logoWithTextImage from '../../styles/images/sqwad_logo_white.svg';

class SideMenu extends Component {
    constructor(props) {
        super(props);
        this.logOut = this.logOut.bind(this);
        this.state = {
            isGod: false
        }
    }

    logOut() {
      app.auth().signOut().then((user, error) => {
        this.setState({ redirect: true })
      });
    }

    async componentDidMount() {
        const cleanEmail = app.auth().currentUser.email.replace('.', '%2E');
        let isGod;
        try {
            const isGodRef = await appDatabasePrimaryFunctions.ref(`whitelist/${cleanEmail}`).once('value');
            isGod = isGodRef.exists();
        } catch (e) {
            console.log(e)
            isGod = false
        }
        this.setState({
            isGod: isGod
        })
    }

    render() {
        const isGod = this.state.isGod;
        const playerPickEnabled = process.env.REACT_APP_HAS_PLAYER_PICK === "true";
        const checkInEnabled = process.env.REACT_APP_HAS_CHECK_IN === "true";
        const prizesEnabled = process.env.REACT_APP_HAS_PRIZES === "true";
        const pushNotificationsEnabled = process.env.REACT_APP_HAS_PUSH_NOTIFICATIONS === "true";
        const badgesEnabled = process.env.REACT_APP_HAS_BADGES === "true";
        const isWinterhawks = process.env.REACT_APP_FIREBASE_PROJECT_ID === "whawksv2";
        const isFirebirds = process.env.REACT_APP_FIREBASE_PROJECT_ID === "coachella-firebirds-app";
        const isCheckers = process.env.REACT_APP_FIREBASE_PROJECT_ID === "checkersdbtest";
        const isDev = process.env.REACT_APP_FIREBASE_PROJECT_ID === "anothertestdb-be6c5";
        const hasActivations = process.env.REACT_APP_ACTIVATIONS_URL;
        const radioEnabled = process.env.REACT_APP_HAS_RADIO === 'true';
        const homeLink = "/admin";
        const scratcherGameLink = "/setupgame";
        const ticketsLink = "/setuptickets";
        const sendPrizesLink = "/sendprizes";
        const badgesLink = "/setupbadges";
        const pointsLink = "/pointvalues";
        const playersLink = "/manageplayers";
        const carouselLink = "/topcarousel";
        const pushNotificationsLink = "/pushnotifications";
        const radioLink = "/radio";
        const userManagementLink = "/usermanagement";
        const settingsLink = "/settings";
        const adminManagement = "/adminManagement";
        const homeButtonsLink = "/homebuttons";
        const sideButtonsLink = "/sidemenubuttons";
        const hasNewFeatures = isFirebirds || isDev || isCheckers;
        return (
          <div className="admin-sidebar">
            <div className="logo">
              <a href={homeLink}>
                <div className="logo-img"><img src={logoWithTextImage} height="30" alt=""/></div>
              </a>
            </div>
            <div className="nav-container">
              <ul className="nav">
                <li className="side-menu-items">
                  <a href={homeLink}>
                    <span className="fa fa-home pre_side_item"/>
                    <span className="">Dashboard</span>
                    <span className="fa fa-chevron-right after_fa_side"/>
                  </a>
                </li>
                {/*<li className="">*/}
                {/*  <a href={userInfoLink}>*/}
                {/*      <span className="fa fa-users pre_side_item"/>*/}
                {/*      <span className="">User Info</span>*/}
                {/*      <span className="fa fa-chevron-right after_fa_side"/>*/}
                {/*  </a>*/}
                {/*</li>*/}
                  {(playerPickEnabled || checkInEnabled) &&
                      <li className="">
                          <a href={scratcherGameLink}>
                              <span className="fa fa-gamepad pre_side_item"/>
                              <span className="">Games</span>
                              <span className="fa fa-chevron-right after_fa_side"/>
                          </a>
                      </li>
                  }
                  {playerPickEnabled &&
                      <li className="">
                          <a href={pointsLink}>
                              <span className="fa fa-plus pre_side_item"/>
                              <span className="">Edit Points</span>
                              <span className="fa fa-chevron-right after_fa_side"/>
                          </a>
                      </li>
                  }
                  {playerPickEnabled &&
                      <li className="">
                          <a href={playersLink}>
                              <span className="fa fa-clipboard pre_side_item"/>
                              <span className="">Players</span>
                              <span className="fa fa-chevron-right after_fa_side"/>
                          </a>
                      </li>
                  }
                <li className="">
                  <a href={carouselLink}>
                      <span className="fa fa-tv pre_side_item"/>
                      <span className="">Top Carousel</span>
                      <span className="fa fa-chevron-right after_fa_side"/>
                  </a>
                </li>
                  {prizesEnabled &&
                      <>
                          <li className="">
                              <a href={ticketsLink}>
                                  <span className="fa fa-trophy pre_side_item"/>
                                  <span className="">Prizes</span>
                                  <span className="fa fa-chevron-right after_fa_side"/>
                              </a>
                          </li>
                          {(isDev || isWinterhawks) &&
                              <li className="">
                                  <a href={sendPrizesLink}>
                                      <span className="fa fa-envelope pre_side_item"/>
                                      <span className="">Send Prizes</span>
                                      <span className="fa fa-chevron-right after_fa_side"/>
                                  </a>
                              </li>
                          }
                      </>
                  }
                  {badgesEnabled &&
                      <li className="">
                          <a href={badgesLink}>
                              <span className="fa fa-certificate pre_side_item"/>
                              <span className="">Badges</span>
                              <span className="fa fa-chevron-right after_fa_side"/>
                          </a>
                      </li>
                  }
                  {pushNotificationsEnabled &&
                      <li className="">
                          <a href={pushNotificationsLink}>
                              <span className="fa fa-bullhorn pre_side_item"/>
                              <span className="">Push Notifications</span>
                              <span className="fa fa-chevron-right after_fa_side"/>
                          </a>
                      </li>
                  }
                  {hasActivations &&
                      <li className="">
                          <a href={hasActivations} target="_blank" rel="noopener">
                              <span className="fa fa-star pre_side_item"/>
                              <span className="">Activations</span>
                              <span className="fa fa-chevron-right after_fa_side"/>
                          </a>
                      </li>
                  }
                  {radioEnabled &&
                      <li className="">
                          <a href={radioLink}>
                              <span className="fa fa-microphone pre_side_item"/>
                              <span className="">Radio</span>
                              <span className="fa fa-chevron-right after_fa_side"/>
                          </a>
                      </li>
                  }
                  <li className="">
                      <a href={userManagementLink}>
                          <span className="fa fa-user pre_side_item"/>
                          <span className="">User Management</span>
                          <span className="fa fa-chevron-right after_fa_side"/>
                      </a>
                  </li>
                  {isGod &&
                      <li className="">
                          <a href={adminManagement}>
                              <span className="fa fa-user-secret pre_side_item"/>
                              <span className="">Admin Management</span>
                              <span className="fa fa-chevron-right after_fa_side"/>
                          </a>
                      </li>
                  }
                  {hasNewFeatures &&
                      <li className="">
                          <a href={settingsLink}>
                              <span className="fa fa-gear pre_side_item"/>
                              <span className="">Settings</span>
                              <span className="fa fa-chevron-right after_fa_side"/>
                          </a>
                      </li>
                  }
                  {hasNewFeatures &&
                      <li className="">
                          <a href={homeButtonsLink}>
                              <span className="fa fa-sort-amount-asc pre_side_item"/>
                              <span className="">Home Buttons</span>
                              <span className="fa fa-chevron-right after_fa_side"/>
                          </a>
                      </li>
                  }
                  {hasNewFeatures &&
                      <li className="">
                          <a href={sideButtonsLink}>
                              <span className="fa fa-list pre_side_item"/>
                              <span className="">Side Menu Buttons</span>
                              <span className="fa fa-chevron-right after_fa_side"/>
                          </a>
                      </li>
                  }
                  <div className="bottom-buttons">
                  <li className="" onClick={this.logOut}>
                    <a href={homeLink}>
                      <span className="fa fa-sign-out pre_side_item"/>
                      <span className="text">Logout</span>
                      <span className="fa fa-chevron-right after_fa_side"/>
                    </a>
                  </li>
                </div>
              </ul>
            </div>
          </div>

        );
    }
}

export default SideMenu
