import React, { useEffect, useState } from 'react';
import '../../styles/css/PreviewHomeScreenButtons.css'; // Make sure to import your CSS file

const Preview = ({ imagePreviews, backgroundImage, isCheckers }) => {
    const [containerWidth, setContainerWidth] = useState(0);

    // Update container width dynamically
    useEffect(() => {
        const updateWidth = () => {
            const previewContainer = document.querySelector('.preview-container');
            if (previewContainer) {
                setContainerWidth(previewContainer.offsetWidth);
            }
        };

        window.addEventListener('resize', updateWidth);
        updateWidth(); // Initial width setting

        return () => window.removeEventListener('resize', updateWidth); // Cleanup on unmount
    }, []);

    const height = containerWidth - (containerWidth * 0.4); // Calculate height proportional to width

    return (
        <div className="preview-container" style={{ backgroundImage: `url(${backgroundImage})` }}>
            {imagePreviews.length > 0 ? (
                imagePreviews.map((image, index) => (
                    <React.Fragment key={index}>
                        <div className="image-wrapper">
                            <img
                                src={image.image}
                                alt={`Preview ${index + 1}`}
                                className="preview-image"
                            />
                        </div>
                        {isCheckers && index === 0 && (
                            <div
                                className="slider-placeholder"
                                style={{ width: '100%', height: `${height}px` }}
                            >
                                <p>Placeholder for slider</p>
                            </div>
                        )}
                    </React.Fragment>
                ))
            ) : (
                <p>No images uploaded</p>
            )}
        </div>
    );
};

export default Preview;
